.NavigationBar {
  flex-direction: column;
}

.AppBar {
  height: 64px;
}

.SecondaryBar {
  top: 64px;
}

.BarContent {
  max-width: 1000px;
  width: 100%;
  min-height: 64px;
}

.SecondaryHeader .BarContent {
  min-height: 56px;
}

.Bar {
  min-height: 64px;
  align-items: center;
  z-index: 20;
  border-radius: 0;
  justify-content: space-between;
}

.main-appbar {
  background: #3040bc;
  color: white;
}

.navbaritem {
  padding-left: 16px;
  padding-right: 16px;
}

.navbaritem {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  width: auto;
}

.headeritem {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  width: auto;
}

@media (hover: hover) {
  .navbaritem:hover {
    border-bottom: 4px solid white;
  }
}

.navbaritem:active {
  border-bottom: 4px solid white;
}

.HeaderContainer {
  position: absolute;
  top: 0;
}

.HeaderBar {
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
    rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  padding-top: 100vh;
  margin-top: -100vh;
}

.HeaderBar.scrolled-top {
  box-shadow: none;
}

.MainHeader .HeaderBar.scrolled-top {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 80%
  );
}

.has-subheader .MainHeader .HeaderBar:not(.scrolled-top) {
  box-shadow: none;
}

.has-subheader .MainHeader .HeaderBar {
  background: #3040bc;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
    rgba(0, 0, 0, 0.117647) 0px 1px 4px;
}

.SecondaryHeader .HeaderBar.scrolled-top {
  background: transparent;
}

.navbaritem .ActionName {
  display: none;
}

.navbaritem .ActionName {
  display: none;
}

.ActionLargeIcon {
  display: none;
}

@media all and (min-width: 640px) {
  .ActionLargeIcon {
    display: flex;
  }

  .ActionLargeIcon + .ActionIcon {
    display: none;
  }
}

.HeaderContainer {
  z-index: 1000;
  width: 100%;
  left: 0;
  right: 0;
  will-change: top, position;
}

.HeaderContainer .MainHeader {
  height: 64px;
  width: 100%;
}

.HeaderContainer .SecondaryHeader {
  height: 56px;
  width: 100%;
}
