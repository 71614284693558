@media screen and (min-width: 640px) {
  .media-mobile-only {
    display: none;
  }
}

.media-large {
  display: none;
}

@media screen and (min-width: 920px) {
  .media-large {
    display: flex;
  }
}

.media-large-inline {
  display: none;
}

@media screen and (min-width: 920px) {
  .media-large-inline {
    display: inline;
  }
}

.media-medium {
  display: none;
}

@media screen and (min-width: 640px) {
  .media-medium {
    display: flex;
  }
}
