.Button {
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  padding: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@media (hover: hover) {
  .Button:hover {
    background: currentColor;
  }

  .Button:hover > * {
    color: white;
  }
}

.Button:active {
  background: currentColor;
}

.Button:active > * {
  color: white;
}

.primary.borderless {
  color: white;
  padding-top: 4px;
  padding-bottom: 4px;
}

.Button.borderless:focus {
  background: rgba(255, 255, 255, 0.01);
}

@media (hover: hover) {
  .primary.borderless:hover,
  .primary.borderless:focus {
    background: rgba(255, 255, 255, 0.08);
  }
}

.primary.borderless:active {
  background: rgba(255, 255, 255, 0.08);
}

.primary {
  color: white;
  border-color: rgb(14, 122, 254);
}

@media (hover: hover) {
  .primary:hover,
  .primary:focus {
    color: white;
    background: rgb(14, 122, 254);
  }
}

.primary:active {
  color: white;
  background: rgb(14, 122, 254);
}

.borderless {
  border: none;
}

.primary.active {
  color: white;
  background: rgb(14, 122, 254);
}

@media (hover: hover) {
  .primary.active:hover,
  .primary.active:focus {
    color: white;
    background: #1884ff;
  }
}

.primary.active:active {
  color: white;
  background: #1884ff;
}
