html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img {
  box-sizing: content-box;
}

div,
button,
ul,
ol,
li,
p,
main,
aside,
header,
a,
input,
label,
section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-shrink: 0;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

p {
  display: inline-block;
}

* {
  position: relative;
  text-align: left; /* as backup */
  text-align: start;
}

/*span {
    display: inline-flex;
}*/

/* currentColor inheritance */
* {
  border-color: currentColor;
}

svg {
  fill: currentColor;
}

/* borders */
* {
  border-width: 0px;
  border-style: solid;
}

* {
  /*overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;*/
}

html,
body {
  overflow-y: initial;
}
