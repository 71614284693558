.PostHeader > :first-child {
  margin-bottom: 8px;
}

.Markdown > pre > code {
  font-size: 80%;
}

.Markdown > pre {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  background: #f3f3f3;
}

.Markdown a {
  font-weight: 600;
  display: inline;
}

@media (hover: hover) {
  .Markdown a:hover,
  .Markdown a:focus {
    text-decoration: underline;
    text-decoration-skip: ink;
  }
}

.Markdown a:active {
  text-decoration: underline;
  text-decoration-skip: ink;
}

p code,
h1 code,
h2 code,
h3 code {
  background: #f3f3f3;
  padding: 2px 6px;
  border-radius: 4px;
}

.Markdown > h1 {
  display: none;
}

.Markdown {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.072px;
}

.Markdown h2 {
  margin-top: 16px;
  font-size: 28px;
}

@media screen and (min-width: 640px) {
  .Markdown {
    font-size: 21px;
    line-height: 31px;
    letter-spacing: -0.063px;
  }
}

.Blog .Section:not(.RelatedPosts) .Content {
  max-width: 800px !important;
}

.RelatedPostsView > *:not(:last-child) {
  margin-bottom: 16px;
}

@media screen and (min-width: 640px) {
  .RelatedPostsView {
    flex-direction: row;
  }

  .RelatedPostsView > *:not(:last-child) {
    margin-bottom: 0px;
  }

  .RelatedPostsView > *:not(:last-child) {
    margin-right: 16px;
  }
}

code.hljs {
  padding: 4px 8px;
}

blockquote {
  padding: 8px;
  background: oldlace;
}

.Markdown {
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
}

.Markdown h1,
.Markdown h2,
.Markdown h3,
.Markdown h4 {
  font-family: Roboto, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans',
    Geneva, Arial, sans-serif;
}

.Markdown a {
  color: rgb(50, 142, 254);
  font-weight: inherit;
}

.Blog .Section:first-child .SectionContent {
  min-height: 60vh;
}

@media print {
  .Blog .Section:first-child .SectionContent {
    min-height: initial;
  }

  .Blog .Section.RelatedPosts {
    display: none !important;
  }
}

.PostContent {
  color: black;
  background: white;
}

.Blog .Title .SectionContent {
  justify-content: flex-end;
}

.Blog .Title .Content {
  padding-bottom: 32px;
}

.Blog .PostContent .Content {
  padding-top: 32px;
}

.Blog .Caption {
  margin-top: 16px;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0;
}

.Blog .card-1 img {
  display: flex;
}

.Blog .card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.Blog .card-1,
.Blog .card-1 img {
  border-radius: 8px;
  overflow: hidden;
}

@media (hover: hover) {
  .Blog .card-1:hover,
  .Blog .card-1:focus {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.11);
  }
}
