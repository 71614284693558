.transition-all {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animated {
  overflow: hidden;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  will-change: transform, opacity;
  transform: translateZ(0);
}

.fast {
  animation-duration: 200ms;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up {
  animation-name: fade-in-up;
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -32px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-down {
  animation-name: fade-in-down;
}
