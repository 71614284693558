input,
textarea {
  min-height: 36px;
  padding: 8px 8px;
  border-radius: 4px;
}

.Section.About .SectionContent {
  background-position-x: 75%;
}

@media all and (min-width: 640px) {
  .Section.About .SectionContent {
    background-image: linear-gradient(
      90deg,
      rgba(41, 14, 179, 0.9) 0%,
      rgba(51, 68, 198, 0.8) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .Section.About .Content > * {
    padding-right: 50%;
  }
}

.Section.Contact .SectionContent {
  padding-bottom: 64px;
}

@media print {
  .SocialLinks {
    display: none;
  }

  .Section.Contact {
    display: none !important;
  }
}
