html,
body {
  background: #3040bc;
  overflow-x: hidden;
}

.App {
  color: white;
  overflow-y: hidden;
}

.Page {
  overflow: hidden;
}

/* Was:
.Section,.Screenshots,.ProjectsGrid,.Card
But that makes fonts blurry...
*/
.Background,
.Screenshots,
.HeaderContainer {
  transform: translateZ(0);
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
