.ErrorBoundary {
  color: black;
  padding: 32px;
  align-self: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  max-width: 1000px;
  background: white;
  border-radius: 2px;
  flex: 1;
  width: 100%;
  margin: 16px;
}

.ErrorBoundary h1 {
  margin-top: 0;
}

.ErrorBoundary .actions {
  max-width: 360px;
}

.ErrorBoundary .actions .primary:not(.active) {
  color: rgb(14, 122, 254);
}

@media (hover: hover) {
  .ErrorBoundary .actions .primary:not(.active):hover {
    color: white;
  }
}

@media print {
  .ErrorBoundary {
    display: none !important;
  }
}
