.SkillName {
  line-height: 24px;
}

.ProjectIcon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 1px 5px #1b1f2326;
}

.ProjectName {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.ProjectUrl {
  padding-top: 4px;
  font-size: 14px;
  line-height: 16px;
}

.BioEntry.media-only-print {
  line-height: 1.5;
}

.BioEntryName {
  text-transform: uppercase;
  min-width: 128px;
  font-weight: 500;
}

.Profile .Social {
  padding-top: 16px;
}

.Profile .Actions {
  max-width: 360px;
}

.HeadingImage {
  border-radius: 50%;
  width: 196px;
  height: 196px;
}

.OverflowButton {
  display: none;
}

.ProjectsGrid > * {
  display: none;
}

.ProjectsGrid > :first-child {
  display: flex;
}

.ProjectsGrid {
  overflow-x: hidden;
  max-width: 1000px;
  margin: 0 auto;
}

.ProjectsOverview {
  align-self: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Skill {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 18px;
}

.Resume .Section:first-child .SectionContent .Content {
  padding-top: 128px;
}

@media all and (max-height: 640px) and (orientation: landscape) {
  .Page.Resume .Section:first-child .SectionContent .Content {
    padding-top: 96px;
  }
}

.LegalNote {
  font-size: 12px;
  line-height: 18px;
  font-style: italic;
}

.ProjectCard {
  flex: 1;
}

@media all and (min-width: 640px) {
  .HeadingImageContainer {
    margin-top: 48px;
  }

  .SectionContent .Content {
    padding-top: 64px;
    padding-bottom: 64px;
    align-self: center;
    max-width: 1000px;
  }

  .Section:first-child .SectionContent .Content {
    padding-top: 128px;
  }

  .ProjectsOverview {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media all and (min-width: 640px) {
  .HeadingImage {
    width: 196px;
    height: 196px;
  }

  .Section.Profile .Content {
    flex-direction: row;
  }

  .Section.Profile .Content > * {
    margin-right: 32px;
  }

  .Section.Profile .Content > :first-child {
    margin-right: 0px;
  }

  .HeadingImageContainer {
    order: 1;
  }
  .HeadingContent {
    order: 0;
  }

  .HeadingImage {
    border-radius: 10%;
  }
}

/* When wide enough, display navigation labels */
@media all and (min-width: 550px) {
  .MainHeader .navbaritem .ActionName {
    padding-left: 16px;
    display: flex;
  }
}

@media all and (min-width: 800px) {
  .navbaritem .ActionName {
    padding-left: 16px;
    display: flex;
  }

  .HeadingImage {
    width: 256px;
    height: 256px;
  }
}

@media all and (min-width: 1000px) {
  .HeadingImage {
    width: 337px;
    height: 337px;
  }
}

@media all and (min-width: 1120px) {
  .ProjectsGrid {
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ProjectCard {
    width: calc(50% - 8px);
    flex: none;
  }

  .ProjectsGrid > * {
    display: flex;
  }

  .ProjectsGrid .Card {
    margin-right: 16px;
  }

  .ProjectsGrid .Card:last-child {
    margin-right: 0px;
  }

  .OverflowLastItemFix {
    width: 1px;
    height: 100%;
    margin-left: -1px;
  }

  .OverflowButton {
    display: flex;
  }
}

.Course {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.54);
  /* padding: 16px 24px; */
  max-width: 392px;
  border-radius: 2px;
  overflow: hidden;
  height: 80px;
  max-width: 360px;
}

.CourseIcon {
  border-radius: 2px;
  overflow: hidden;
  width: 80px;
  height: 80px;
}

.CourseTitle {
  flex: 1;
  padding: 8px 16px;
  /* height: 96px; */
  font-size: 13px;
  line-height: 20px;
  justify-content: center;
}

.CourseTitle {
  flex: 1;
  /* padding: 16px 24px; */
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.ProjectHeadline {
  display: flex;
  flex: 1;
  min-height: 40px;
}

.ProjectTimeFrame {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media print {
  .Page.Resume {
    font-size: 83%;
  }

  .ProjectCard {
    margin-top: 0;
  }

  .ProjectCard:first-child {
    margin-top: 0;
  }

  .ProjectHeadline {
    display: flex;
    flex: 1;
    min-height: 20px;
  }

  .CardContent * {
    text-align: justify !important;
  }

  .ProjectTimeFrame {
    font-weight: normal;
    font-size: 12px !important;
    line-height: 20px;
  }
}
